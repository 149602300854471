import React from "react"
import { Location } from '@reach/router'
import Hero from "../../components/hero-quickconnect"
import Content from "../../components/content-quickconnect"

import "../../assets/index.css"
import Layout from "../../layouts/layout"

export default () => (
    <Layout title="Field Service QuickConnect | Ladd Partners">
        <Hero/>

        <Location>
            {({ location }) => {
                return  <><Content location={{ location }} /></>
            }}
        </Location>
    </Layout>
)
